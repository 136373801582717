import Box from 'components/Box';
import React from 'react';
import styled from 'styled-components';

const Thumbnail = ({ src, alt, onClick, onError }) => {
  return (
    <Box position="relative" pt="100%" h={0} overflow="hidden">
      <Picture onClick={onClick}>
        <img alt={alt} onError={onError} src={src} />
      </Picture>
    </Box>
  );
};

const Picture = styled.picture`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default Thumbnail;
