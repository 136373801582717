import React, { useState } from 'react';

import Box from 'components/Box';
import Thumbnail from 'components/Thumbnail';

const GalleryCard = ({ thumbnail, oldThumbnailFallback, onClick }) => {
  const [isBroken, setBroken] = useState(false);
  const [thumbnailSrc, setThumbnailSrc] = useState(thumbnail.src);

  return (
    <Box display={isBroken ? 'none' : undefined}>
      {/* Let's hide the name from the thumbnail so the (inappropriate) names are not so visible */}
      {/* <Flex mb="0.5em" alignCenter justifyContent="space-between">
        <Username small>{username}</Username>
        <Timestamp small>{timestamp}</Timestamp>
      </Flex> */}

      <Thumbnail
        onClick={onClick}
        src={thumbnailSrc}
        alt={thumbnail.alt}
        onError={() => {
          // If server doesn't return thumbnail
          // use old endpoint as a fallback
          setThumbnailSrc(oldThumbnailFallback.src);
          // If old endpoint also fails, set image as broken and hide
          if (thumbnailSrc === oldThumbnailFallback.src) {
            setBroken(true);
          }
        }}
      />
    </Box>
  );
};

export default GalleryCard;
