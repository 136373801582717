import { CensorSensor } from 'censor-sensor';
import { getPieceURL } from './api/getPieceURL';

const censor = new CensorSensor();
censor.setCleanFunction((str) => Array.from(str, (x) => '').join(''));

export const updateCanvasSize = (canvas, params) => {
  if (!canvas || !params) return;
  canvas.width = params.width;
  canvas.height = params.height;
};

export const fadeOut = (audio) => {
  if (audio.volume > 0.1) {
    audio.volume -= 0.01;
    setTimeout(() => fadeOut(audio), 10);
  } else {
    audio.pause();
    audio.currentTime = 0;
    audio.volume = 0;
  }
};

export const fadeIn = (audio) => {
  if (audio.volume < 0.5) {
    audio.volume += 0.01;
    setTimeout(() => fadeIn(audio), 10);
  }
};

export const isEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const isDescription = (desc) => {
  const description = desc.replace(/\s/g, '');
  return description && description.length > 8;
};

export const formatPieceParams = ({ caption, id, lastLikedBy, username, width, ...rest }) => {
  return {
    caption: caption ? censor.cleanProfanity(caption) : caption,
    id,
    likedBy: lastLikedBy ? lastLikedBy.map(({ username }) => username) : [],
    src: getPieceURL(id, width),
    username: username ? censor.cleanProfanity(username) : username,
    ...rest,
  };
};
