import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-shortformat';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ContentBox from 'components/ContentBox';
import DropDownMenu from './components/DropDownMenu';
import Flex from 'components/Flex';
import GalleryCard from './components/GalleryCard';
import Preview from './components/Preview';
import Spinner from '../Spinner';
import Title from '../Title';
import { useBreakpointAlias } from 'hooks/useBreakpoint';
import { getThumbnailURL } from 'helpers/api/getThumbnailURL';
import { useGallery } from 'hooks/useGallery';

// Context
import { ModalContext } from 'context/modal';

const POPULAR_TIMEFRAME_OPTIONS = [
  { label: '24 hours', value: '-1 day' },
  { label: '30 days', value: '-30 days' },
  { label: '1 year', value: '-1 year' },
  { label: 'All time', value: null },
];

const Gallery = ({
  title,
  type,
  items,
  pageNumber,
  setPageNumber,
  setLoading,
  fetchedCallback,
  loading,
}) => {
  const [pieces, setPieces] = useState([]);
  const [createdAtBegin, setCreatedAtBegin] = useState(POPULAR_TIMEFRAME_OPTIONS[0].value);
  const { modal, setModalParams } = useContext(ModalContext);

  const breakpointAlias = useBreakpointAlias();

  const numCols = {
    xs: 3,
    sm: 4,
    md: 4,
    lg: 5,
  }[breakpointAlias || 'xs'];

  const updatePieces = (newPieces) => {
    // Filter out duplicates (caused by paging) from old and new pieces
    setPieces(
      [...pieces, ...newPieces].filter(
        (piece, index, array) => array.findIndex(({ id }) => id === piece.id) === index
      )
    );
    fetchedCallback && fetchedCallback();
  };

  useGallery({ type, items, pageNumber, createdAtBegin }, updatePieces);

  const onChangePeriod = (option) => {
    setPieces([]);
    setPageNumber(0);
    setLoading(true);
    setCreatedAtBegin(option);
  };

  const onBrowserBack = () => {
    setModalParams({ opened: false });
    window.removeEventListener('popstate', onBrowserBack);
  };

  return (
    <ContentBox>
      <Flex alignCenter justifyContent="space-between">
        <Title>{title}</Title>

        {items && <Link to={type}>{`See all ${type} \uf105`}</Link>}

        {!items && type.toLowerCase() === 'popular' && (
          <DropDownMenu options={POPULAR_TIMEFRAME_OPTIONS} onChangeCallback={onChangePeriod} />
        )}
      </Flex>

      <GridContainer numCols={numCols}>
        {pieces.slice(0, items ? numCols : undefined).map((piece) => (
          <GalleryCard
            key={piece.id}
            username={piece.username}
            timestamp={moment(piece.createdAt).short(true)}
            onClick={() => {
              // Hack to prevent a standard behaviour of the BACK button
              // Even if you change the url via React Router, it will refresh the page
              // We need to save the existing scroll position and old uploaded content
              window.history.pushState(null, null, document.URL);
              window.addEventListener('popstate', onBrowserBack);

              setModalParams({
                ...modal,
                opened: true,
                onDismiss: () => {
                  // Manually pop history entry from stack
                  window.history.go(-1);
                  window.removeEventListener('popstate', onBrowserBack);
                },
                component: (
                  <Preview
                    piece={piece}
                    onDismiss={() => {
                      // Manually pop history entry from stack
                      window.history.go(-1);
                      window.removeEventListener('popstate', onBrowserBack);
                      setModalParams({ opened: false });
                    }}
                  />
                ),
              });
            }}
            thumbnail={getThumbnailURL(type, piece)}
            oldThumbnailFallback={getThumbnailURL(type, piece, true)}
          />
        ))}
      </GridContainer>

      {loading && <Spinner centered={true} />}
    </ContentBox>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ numCols }) => `repeat(${numCols}, minmax(0, 1fr))`};
  grid-gap: 0.5em 0.5em;
`;

Gallery.propTypes = {
  loading: PropTypes.bool,
  pageNumber: PropTypes.number,
  fetchedCallback: PropTypes.func,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['recent', 'popular']).isRequired,
};

Gallery.defaultProps = {
  pageNumber: 0,
  loading: false,
};

export default Gallery;
