import Button from 'components/Button';
import Flex from 'components/Flex';
import Span from 'components/Span';
import { getThumbnailURL } from 'helpers/api/getThumbnailURL';
import { useBreakpointMax } from 'hooks/useBreakpoint';
import moment from 'moment-shortformat';
import React, { useEffect } from 'react';
import useArchive from 'views/QuickAdmin/useArchive';
import useUnarchive from 'views/QuickAdmin/useUnarchive';

const QuickAdminPiece = ({ piece, onArchived, onUnarchived, ...rest }) => {
  const isMobileBreakpoint = useBreakpointMax('sm');

  const {
    archive,
    error: archiveError,
    status: archiveStatus,
    response: archiveResponse,
  } = useArchive();
  const {
    error: unarchiveError,
    status: unarchiveStatus,
    response: unarchiveResponse,
    unarchive,
  } = useUnarchive();

  const isLoading = archiveStatus === 'loading' || unarchiveStatus === 'loading';
  const archiveSuccess = archiveResponse && archiveResponse.success;
  const unarchiveSuccess = unarchiveResponse && unarchiveResponse.success;
  const thumbnail = getThumbnailURL('recent', piece);

  useEffect(() => {
    if (archiveStatus === 'complete' && archiveSuccess && onArchived) {
      onArchived();
    }
  }, [archiveStatus, archiveSuccess, onArchived]);

  useEffect(() => {
    if (unarchiveStatus === 'complete' && unarchiveSuccess && onUnarchived) {
      onUnarchived();
    }
  }, [onUnarchived, unarchiveStatus, unarchiveSuccess]);

  return (
    <Flex flexDirection={isMobileBreakpoint ? 'column' : 'row'} fullWidth {...rest}>
      <Flex
        backgroundColor="white"
        center
        width={isMobileBreakpoint ? '100%' : '280px'}
        height="280px"
      >
        <img src={thumbnail.src} alt={thumbnail.alt} />
      </Flex>

      <Flex
        column
        backgroundColor="white"
        ml={isMobileBreakpoint ? 0 : 2}
        mt={isMobileBreakpoint ? '1px' : 0}
        width={isMobileBreakpoint ? '100%' : '420px'}
        height={isMobileBreakpoint ? undefined : '280px'}
        px="16px"
        py="12px"
      >
        <Flex>
          <Span fontWeight={piece.creatorId ? 'bold' : undefined}>{piece.username}</Span>
        </Flex>

        <Flex>
          <Span>{piece.caption}</Span>
        </Flex>

        <Flex flex={1} />

        <Flex>
          <Span fontSize="0.8em">{`Created: ${moment(piece.createdAt).short(true)}`}</Span>
        </Flex>

        {piece.autoArchivedAt && (
          <Flex>
            <Span fontSize="0.8em">{`Auto-archived: ${moment(piece.autoArchivedAt).short(
              true
            )}`}</Span>
          </Flex>
        )}

        {piece.archivedAt && (
          <Flex>
            <Span fontSize="0.8em">{`Archived: ${moment(piece.archivedAt).short(true)}`}</Span>
          </Flex>
        )}

        <Flex alignItems="center">
          <a href={`/piece/${piece.id}`}>{piece.id}</a>

          <Flex flex={1} />

          {archiveError || unarchiveError ? (
            <span>{archiveError || unarchiveError}</span>
          ) : isLoading ? (
            <span>Processing...</span>
          ) : !archiveSuccess || !unarchiveSuccess ? (
            <>
              <Button color="red" disabled={isLoading} ghost onClick={() => archive({ piece })}>
                Archive
              </Button>

              {(piece.archivedAt || piece.autoArchivedAt) && (
                <Button
                  color="green"
                  disabled={isLoading}
                  ghost
                  ml={3}
                  onClick={() => unarchive({ piece })}
                >
                  Unarchive
                </Button>
              )}
            </>
          ) : (
            <span>{'Unarchived'}</span>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default QuickAdminPiece;
