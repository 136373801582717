const PIECE_URL = process.env.REACT_APP_PIECE_URL;

export const getThumbnailURL = (type, piece, isOld) => {
  const resolve = {
    type,
    alt: piece.username
  };

  resolve.src = type.toLowerCase() === 'popular' && isOld
    ? `https://cdn.thisissand.com/files/${piece.id}/thumb/280`
    : `${PIECE_URL}/${piece.id}/280`;

  return resolve;
};
