const PIECE_URL = process.env.REACT_APP_PIECE_URL;

export const getPieceURL = (id, width) => {
  const isPieceWiderThanMaxSize = width > 750;

  if (isPieceWiderThanMaxSize) {
    // Take the widest possible preview
    return `${PIECE_URL}/${id}/750`;
  }
  // Take a smaller one
  return `${PIECE_URL}/${id}/480`;
};
