import cx from 'classnames';
import Divider from 'components/Divider';
import Flex from 'components/Flex';
import Header from 'components/Header';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Logo from 'components/Logo';
import Modal from 'components/Modal';
import ReportModal from 'components/Modal/components/Report';
import Spinner from 'components/Spinner';
import Timestamp from 'components/Timestamp';
import Username from 'components/Username';
import { copyPieceURL } from 'helpers/api/copyPieceURL';
import moment from 'moment-shortformat';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SettingsIcon from 'static/settings.svg';
import styles from './styles.module.css';

const API_URL = process.env.REACT_APP_API_URL;

// TODO: Refactor Preview component.
const Preview = ({ piece, shadow, onDismiss }) => {
  const { createdAt, id, src, username, caption, likedBy } = piece;

  const [loaded, setLoaded] = useState(false);
  const [imageSrc, updateImageSrc] = useState(src);
  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
  const [reportModalIsOpen, setReportModalIsOpen] = useState(false);

  const menuModalRef = useRef();
  const reportModalRef = useRef();

  const closeMenuModal = () => setMenuModalIsOpen(false);

  const classNames = cx({ [styles.hidden]: !loaded });

  useEffect(() => {
    fetch(`${API_URL}/pieces/${id}/inc-view-count`, {
      method: 'POST'
    })
  }, [id])

  return (
    <>
      {!loaded && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      <div
        className={cx(styles.preview, {
          [styles.preview_shadow]: shadow,
          [styles.preview_hidden]: !loaded,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        {/*Header is for mobile breakpoint only*/}
        <Header className={styles.header} secondary={true}>
          <RouterLink to="/" onClick={onDismiss}>
            <Logo />
          </RouterLink>
          <Icon className={styles.close_icon} name="close" onClick={onDismiss} />
        </Header>
        <div className={styles.image}>
          <img
            src={imageSrc}
            className={classNames}
            alt={caption || username}
            onLoad={(e) => {
              // Re-fetch image if server failed
              const hasBrokenImage = e.target.naturalWidth === 1;

              if (hasBrokenImage) {
                updateImageSrc(imageSrc + `?${Date.now()}`);
              }

              setLoaded(true);
            }}
          />
        </div>
        <div className={styles.content}>
          <div>
            <Username big={true}>{username}</Username>
            <Divider />
            <div className={styles.caption}>{caption}</div>
          </div>
          <div>
            <Divider />
            <div className={styles.likes}>
              {likedBy.length > 0 && (
                <>
                  <span className={styles.heart}>♥</span>
                  {likedBy.map((username, i) => {
                    if (i === likedBy.length - 1) {
                      return (
                        <Username key={username} small>
                          {username}
                        </Username>
                      );
                    }
                    return <Username key={username} small>{`${username}, `}</Username>;
                  })}
                </>
              )}
            </div>
            <Flex justifyContent="space-between">
              <Timestamp small>{moment(createdAt).short()}</Timestamp>
              <img src={SettingsIcon} alt="settings" onClick={() => setMenuModalIsOpen(true)} />
            </Flex>
          </div>
        </div>
      </div>

      <Modal
        ref={menuModalRef}
        id="piece-menu"
        secondary={true}
        isMenu={true}
        opened={menuModalIsOpen}
        onDismiss={closeMenuModal}
      >
        <div className={styles.links}>
          <Link
            onClick={() => {
              closeMenuModal();
              copyPieceURL(id);
            }}
          >
            Copy link
          </Link>

          <Link href={`${API_URL}/files/${id}/download`} onClick={closeMenuModal}>
            Download
          </Link>

          <Link
            warning={true}
            onClick={() => {
              closeMenuModal();
              setReportModalIsOpen(true);
            }}
          >
            Report
          </Link>

          <Link onClick={closeMenuModal} strong={true}>
            Cancel
          </Link>
        </div>
      </Modal>

      <Modal
        ref={reportModalRef}
        id="report-piece"
        opened={reportModalIsOpen}
        component={
          <ReportModal
            pieceId={id}
            onCloseTrigger={() => {
              setReportModalIsOpen(false);
            }}
          />
        }
        onDismiss={() => setReportModalIsOpen(false)}
      />
    </>
  );
};

Preview.propTypes = {
  shadow: PropTypes.bool,
  piece: PropTypes.shape({
    id: PropTypes.number.isRequired,
    caption: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    lastLikedBy: PropTypes.arrayOf(PropTypes.object),
  }),
};

Preview.defaultProps = {
  shadow: true,
};

export default Preview;
